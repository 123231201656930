import React from "react";
import { Route, Navigate } from "react-router-dom";
import NotFound from "../../../pages/NotFound";

import QuickProjects from "../index";
import HomePage from "../pages";

import * as CostCodes from "./cost-codes.route";
import * as ProjectTypes from "./project-types.route";
import * as Projects from "./projects.route";
import * as Manpowers from "./manpowers.route";
import * as ProjectMaterials from "./project-materials.route";
import * as TaskMaterials from "./task-materials.route";
import * as TaskComments from "./task-comments.route";
import * as ManpowerLogs from "./manpower-logs.route";
import * as Tasks from "./tasks.route";
import * as Rfis from "./rfis.route";
import * as Reports from "./reports.route";
import * as GanttChart from "./gantt-chart.route";
import * as Dashboard from "./dashboard.route";
import * as Milestones from "./milestones.route";
import * as ProjectCosts from "./project-costs.route";
import * as ManpowerTypes from "./manpower-types.route";
import * as TaskReports from "./task-reports.route";
import * as ProjectResources from "./project-resources.route";
import * as TaskManpowers from "./task-manpowers.route";
import * as TaskDocuments from "./task-documents.route";
import * as TaskMachines from "./task-machines.route";
import * as TaskHandTools from "./task-hand-tools.route";
import * as DailyReports from "./daily-reports.route";
import * as TaskWorks from "./task-works.route";
import * as BudgetTrackers from "./budget-tracker.route";

export const register = (props) => {
  const basePath = "/quick-projects";

  return (
    <Route path={basePath} element={<QuickProjects />}>
      {Dashboard.register()}
      {CostCodes.register()}
      {ProjectTypes.register()}
      {Projects.register()}
      {Manpowers.register()}
      {ProjectMaterials.register()}
      {TaskMaterials.register()}
      {TaskComments.register()}
      {ManpowerLogs.register()}
      {TaskReports.register()}
      {Tasks.register()}
      {Rfis.register()}
      {Reports.register()}
      {GanttChart.register()}
      {Milestones.register()}
      {ProjectCosts.register()}
      {ManpowerTypes.register()}
      {ProjectResources.register()}
      {TaskManpowers.register()}
      {TaskDocuments.register()}
      {TaskMachines.register()}
      {TaskHandTools.register()}
      {DailyReports.register()}
      {TaskWorks.register()}
      {/* {BudgetTrackers.register()} */}


      <Route
        path={basePath}
        element={<Navigate to={`${basePath}/dashboard`} replace />}
      // element={<HomePage />}
      />

      <Route path="*" element={<NotFound returnPath={basePath} />} />
    </Route>
  );
};
