export default {
  slugs: {
    singular: "budget-tracker",
    plural: "budget-trackers",
  },
  entityNames: {
    singular: "Budget Tracker",
    plural: "Budget Trackers",
  },
};
