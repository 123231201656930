import { axios } from "../../../../../../App";
import config from "../../config";
import { v4 } from "uuid";


export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-Edit`,
  title: `Edit Phase`,
  pageTitle: `Edit Phase`,
  breadcrumbs: [
    // {
    //   path: "/",
    //   title: "Home",
    // },
    {
      path: `/budget-tracker`,
      title: config?.entityNames?.plural,
    },
    // {
    //   path: "",
    //   title: `Edit ${config?.entityNames?.singular}`,
    // },
  ],
  components: [
    {
      span: 24,
      component: "select",
      label: "Milestones",
      name: "task",
      placeholder: "Select Milestone",
      selectable: true,
      required: true,
      rules: [
        {
          required: true,
          message: "Milestone is required!",
        },
      ],
      mode: "multiple",
    },

  ],
  actions: {
    onBack: () => navigate(`/budget-tracker`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(`/budget-tracker-phases/${data?.id}`, {
          data: {
            ...values,
            uid: v4(),
          },
        });

        console.log('Success', response)

        if (response?.data) {

          return {
            success: true,
            message: `Edit new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Edit new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
