import { axios } from "../../../../../../App";
import config from "../../config";
import { v4 } from "uuid";


export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add Phase`,
  pageTitle: `Add Phase`,
  breadcrumbs: [
    // {
    //   path: "/",
    //   title: "Home",
    // },
    {
      path: `/budget-tracker`,
      title: config?.entityNames?.plural,
    },
    // {
    //   path: "",
    //   title: `Add ${config?.entityNames?.singular}`,
    // },
  ],
  components: [
    {
      span: 24,
      component: "select",
      label: "Milestones",
      name: "task",
      placeholder: "Select Milestone",
      selectable: true,
      required: true,
      rules: [
        {
          required: true,
          message: "Milestone is required!",
        },
      ],
      mode: "multiple",
    },

  ],
  actions: {
    onBack: () => navigate(`/quick-projects/${config?.slugs?.singular}`),
    onFinish: async (values, data) => {

      const phaseIds = data?.map(item => item?.id);

      try {
        const response = await axios.post(`/budget-tracker-phases`, {
          data: {
            ...values,
            uid: v4(),
          },
        });


        if (response?.data) {
          const editBudget = await axios.put(`/budget-trackers/${values?.budget_tracker}`, {
            data: {
              phase: [...phaseIds, response.data.data.id]
            }
          })

          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
