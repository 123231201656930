import React from "react";
import SharedLayout from "../../components/SharedLayout";
import { makeNavbar } from "./nav";
import { Outlet } from "react-router-dom";

const QuickDrive = () => {
  return (
    <SharedLayout menuItem={{ title: "Quick Drive", path: "/quick-drive" }} makeNavbar={makeNavbar}>
      <Outlet />
    </SharedLayout>
  );
};

export default QuickDrive;
