import React from "react";
import { Route } from "react-router-dom";
import BudgetTrackerList from "../pages/BudgetTracker/List";
import BudgetTrackerProjectList from "../pages/BudgetTracker/Project/List";
import BudgetTrackerAdd from "../pages/BudgetTracker/Project/Add";
import BudgetTrackerEdit from "../pages/BudgetTracker/Project/Edit";

export const register = (props) => {
    const basePath = "list";

    return (
        <>
            <Route path={''} element={<BudgetTrackerList />} exact={true} />
            <Route path={`${basePath}/:id`} element={<BudgetTrackerProjectList />} />
            <Route path={`${basePath}/add/:id`} element={<BudgetTrackerAdd />} />
            <Route path={`${basePath}/edit/:id`} element={<BudgetTrackerEdit />} />
        </>
    );
};
