import { MdArrowBack, MdPeopleAlt } from "react-icons/md";
import { BiCategoryAlt, BiTask } from "react-icons/bi";
import { TbReportAnalytics, TbReport } from "react-icons/tb";
import { FiSettings } from "react-icons/fi";
import { RiPieChart2Fill } from "react-icons/ri";
import { BsQuestionOctagon, BsChatDots } from "react-icons/bs";
import { FaLayerGroup, FaTools } from "react-icons/fa";
import { AiOutlineProject } from "react-icons/ai";
import { IoDocuments } from "react-icons/io5";

const BASE_SLUG = "/quick-projects";

export const makeNavbar = ({ navigate, user }) => {
  const makeItem = ({ icon, id, label, scopes }) => {
    return {
      id,
      key: `${BASE_SLUG}/${id}`,
      label,
      icon,
      onClick: () => navigate(`${BASE_SLUG}/${id}`),
      scopes,
    };
  };

  let roles = [];

  user?.app_roles?.forEach((appRole) => {
    appRole?.permissions?.forEach((appPermission) => {
      if (!roles?.includes(appPermission)) {
        roles?.push(appPermission);
      }
    });
  });

  roles = roles?.join(",");

  const menuItems = [
    makeItem({
      id: "dashboard",
      label: "Dashboard",
      icon: <RiPieChart2Fill style={styles.icon} />,
      // by default, "scopes" has "view" and "update"
      // when specified custom scopes, all items inside the array will be all of your available scopes
      scopes: [
        {
          id: "view",
          label: "View",
        },
        {
          id: "office-view",
          label: "Office Special View",
        },
        {
          id: "site-view",
          label: "Site Special View",
        },
        {
          id: "super-admin-view",
          label: "Super Admin Special View",
        },
      ],
    }),
    {
      key: "projects",
      label: "Projects",
      icon: <AiOutlineProject style={styles.icon} />,
      children: [
        makeItem({
          id: "projects",
          label: "Projects List",
          icon: <AiOutlineProject style={styles.icon} />,
        }),
        makeItem({
          id: "project-types",
          label: "Project Types",
          icon: <BiCategoryAlt style={styles.icon} />,
        }),
        // makeItem({
        //   id: "project-resources",
        //   label: "Project Resources",
        //   icon: <FaLayerGroup style={styles.icon} />,
        // }),
      ],
    },
    {
      key: "tasks",
      label: "Tasks",
      icon: <BiTask style={styles.icon} />,
      children: [
        makeItem({
          id: "tasks",
          label: "Tasks",
          icon: <BiTask style={styles.icon} />,
        }),
        makeItem({
          id: "task-materials",
          label: "Task Materials",
          icon: <FaLayerGroup style={styles.icon} />,
        }),
        makeItem({
          id: "task-works",
          label: "Task Works",
          icon: <FaLayerGroup style={styles.icon} />,
        }),
        makeItem({
          id: "task-manpowers",
          label: "Task Manpowers",
          icon: <MdPeopleAlt style={styles.icon} />,
        }),
        makeItem({
          id: "task-documents",
          label: "Task Documents",
          icon: <IoDocuments style={styles.icon} />,
        }),
        makeItem({
          id: "task-machines",
          label: "Task Machines",
          icon: <FiSettings style={styles.icon} />,
        }),
        makeItem({
          id: "task-hand-tools",
          label: "Task Hand Tools",
          icon: <FaTools style={styles.icon} />,
        }),
        makeItem({
          id: "task-comments",
          label: "Task Comments",
          icon: <BsChatDots style={styles.icon} />,
        }),
      ],
    },
    makeItem({
      id: "rfis",
      label: "RFIs",
      icon: <BsQuestionOctagon style={styles.icon} />,
    }),
    makeItem({
      id: "daily-reports",
      label: "Daily Reports",
      icon: <TbReportAnalytics style={styles.icon} />,
    }),
    // {
    //   key: "manpowers",
    //   label: "Manpower",
    //   icon: <MdPeopleAlt style={styles.icon} />,
    //   children: [
    //     makeItem({
    //       id: "manpowers",
    //       label: "Manpower",
    //       icon: <MdPeopleAlt style={styles.icon} />,
    //     }),
    //     makeItem({
    //       id: "manpower-types",
    //       label: "Manpower Types",
    //       icon: <BiCategoryAlt style={styles.icon} />,
    //     }),
    //   ],
    // },
    makeItem({
      id: "reports",
      label: "Reports",
      icon: <TbReportAnalytics style={styles.icon} />,
      scopes: [
        {
          id: "view",
          label: "View",
        },
      ],
    }),
    {
      key: "settings",
      label: "Settings",
      icon: <FiSettings style={styles.icon} />,
      children: [
        makeItem({
          id: "cost-codes",
          label: "Cost Codes",
          icon: <BiCategoryAlt style={styles.icon} />,
        }),
        makeItem({
          id: "manpower-types",
          label: "Manpower Types",
          icon: <BiCategoryAlt style={styles.icon} />,
        }),
      ],
    },
    // makeItem({
    //   id: "budget-tracker",
    //   label: "Budget Tracker",
    //   icon: <TbReportAnalytics style={styles.icon} />,
    // }),
    {
      key: "back-to-home",
      label: "Return Home",
      icon: <MdArrowBack style={styles.icon} />,
      onClick: () => navigate("/"),
    },
  ];

  let filteredItems = [];

  if (user) {
    menuItems?.forEach((item) => {
      const uid = item?.children ? item?.key : item?.id;

      if (item?.key === "back-to-home") {
        filteredItems?.push(item);
      }

      if (item?.children) {
        const newChildren = item?.children?.filter((item) => {
          const subItemUid = item?.id;

          return roles?.includes(`QUICK-PROJECTS.${subItemUid?.toUpperCase()}`);
        });

        if (newChildren?.length > 0) {
          item["children"] = newChildren;
          filteredItems?.push(item);
        }
      } else {
        if (roles?.includes(`QUICK-PROJECTS.${uid?.toUpperCase()}`)) {
          filteredItems?.push(item);
        }
      }
    });
  } else {
    menuItems?.forEach((item) => {
      if (item?.children) {
        item?.children?.forEach((child) => {
          filteredItems?.push(child);
        });
      } else {
        filteredItems?.push(item);
      }
    });
  }

  return filteredItems;
};

const styles = {
  icon: {
    fontSize: 16,
    color: "#CF6C57",
  },
};
