import React from "react";
import { Route, Navigate } from "react-router-dom";
import NotFound from "../../../pages/NotFound";


import * as BudgetTrackers from "./budget-tracker.route";
import BudgetTracker from "..";

export const register = (props) => {
  const basePath = "/budget-tracker";

  return (
    <Route path={basePath} element={<BudgetTracker />}>

      {BudgetTrackers.register()}

      <Route path="*" element={<NotFound returnPath={basePath} />} />
    </Route>
  );
};
