import React, { useEffect, useState } from "react";
import { ReloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Table, Space, Button, PageHeader, Breadcrumb, Tooltip } from "antd";
import ColumnSelector from "../ColumnSelector";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import _ from "lodash";

const DataTable = ({
  data,
  config,
  loading,
  pagination,
  actions,
  buttons = {},
  onBack = null,
  Toolbar = null,
  showsTopBar = true,
  showsBreadcrumbs = true,
}) => {
  const [selectedColumns, setSelectedColumns] = useState([]);

  const selectColumn = (dataIndex) => {
    if (selectedColumns?.indexOf(dataIndex) > -1) {
      setSelectedColumns((prev) => prev?.filter((item) => item !== dataIndex));
    } else {
      setSelectedColumns((prev) => prev?.concat(dataIndex));
    }
  };

  const renderButtons = () => {
    if (!_.isEmpty(buttons)) {
      return buttons;
    } else {
      return [
        <Tooltip placement="top" title="Add new item">
          <Button icon={<PlusOutlined />} onClick={actions?.onAdd} />
        </Tooltip>,
        <Tooltip placement="top" title="Refresh">
          <Button
            icon={<ReloadOutlined />}
            onClick={actions?.onRefresh}
            loading={loading}
          />
        </Tooltip>,
      ];
    }
  };

  useEffect(() => {
    setSelectedColumns(
      config?.columns
        ?.filter((column) => column?.selectable)
        ?.map((column) => column?.dataIndex)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      {showsBreadcrumbs && (
        <Breadcrumb style={styles.breadcrumbs}>
          {config?.breadcrumbs?.map((breadcrumb, index) => (
            <Breadcrumb.Item key={index}>
              <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}

      {showsTopBar && (
        <PageHeader
          title={config?.pageTitle}
          extra={[
            <Space>
              {renderButtons()}

              <ColumnSelector
                value={selectedColumns}
                columns={config?.columns?.filter(
                  (column) => column?.selectable
                )}
                onSelect={selectColumn}
              />
            </Space>,
          ]}
          onBack={onBack}
          style={styles.pageHeader}
        ></PageHeader>
      )}

      {Toolbar}

      {selectedColumns?.length > 0 && (
        <Table
          style={{ overflow: "auto" }}
          columns={config?.columns?.filter(
            (column) =>
              column?.title === "Actions" ||
              selectedColumns?.indexOf(column?.dataIndex) > -1
          )}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={pagination}
          loading={loading}
        // onChange={handleTableChange}
        />
      )}
    </>
  );
};

const styles = {
  toolbar: {
    marginBottom: 24,
  },
  rightToolbar: {
    textAlign: "right",
  },
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
};

export default DataTable;
