import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "input",
      label: "Name",
      name: "name",
      placeholder: "Enter BOQ Name",
      required: true,
      rules: [
        {
          required: true,
          message: "BOQ is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Project",
      name: "projects",
      placeholder: "Enter Project",
      required: true,
      rules: [
        {
          required: true,
          message: "Project required!",
        },
      ],
    },
    {
      span: 12,
      component: "date",
      label: "Date",
      name: "date",
      placeholder: "Enter Date",
      required: true,
      rules: [
        {
          required: true,
          message: "Date is required!",
        },
      ],
    },
    {
      span: 12,
      component: "number",
      label: "Boq Number",
      name: "boq_number",
      placeholder: "Enter BOQ Number",
      required: true,
      rules: [
        {
          required: true,
          message: "BOQ Number is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Approved By",
      name: "approved_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },

  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values) => {
      try {
        const response = await axios.post(`/sub-contractor-boqs`, {
          data: {
            ...values,
            uid: v4(),
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
