import config from "../../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  // pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/budget-tracker",
      title: "Budget Tracker",
    },
    // {
    //   path: "",
    //   title: config?.entityNames?.plural,
    // },
  ],
  columns: [
    {
      title: "Name",
      dataIndex: "index",
      selectable: true,
      render: (text) =>
      (
        <span
          className="html-cell"
        >{`R${text}`}</span>
      ),
    },
    {
      title: "Cost per Sq Ft (Carpet Area)",
      dataIndex: "carpet_area_cost",
      selectable: true,
    },
    {
      title: "Cost per Sq Ft (Construction Area)",
      dataIndex: "construction_area_cost",
      selectable: true,
    },
    {
      title: "Total Estimated Budget",
      dataIndex: "total_estimated_budget",
      selectable: true,
    },
    {
      title: "Total Actual Budget",
      dataIndex: "total_actual_budget",
      selectable: true,
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   selectable: true,
    //   sorter: (a, b) => a?.description?.localeCompare(b?.description),
    //   render: (text) =>
    //     text !== "" ? (
    //       <span
    //         className="html-cell"
    //         dangerouslySetInnerHTML={{ __html: text }}
    //       ></span>
    //     ) : (
    //       "N/A"
    //     ),
    // },
  ],
  ...config,
};
