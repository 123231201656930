import React from "react";
import SharedLayout from "../../components/SharedLayout";
import { makeNavbar } from "./nav";
import { Outlet } from "react-router-dom";

const BudgetTracker = () => {
  return (
    <SharedLayout menuItem={{ title: "Budget Tracker", path: "/budget-tracker" }} makeNavbar={makeNavbar}>
      <Outlet />
    </SharedLayout>
  );
};

export default BudgetTracker;
