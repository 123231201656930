import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Breadcrumb, Button, Col, Collapse, Form, Input, InputNumber, PageHeader, Row, Select, Space, notification } from "antd";
import { Helmet } from "react-helmet";
import { v4 } from "uuid";
import ReactQuill from "react-quill";
import { fetchData } from "../../../../../../utils/helpers/data.helper";
import FormElement from "../../../../components/FormElement";
import { makeConfig } from "./config";
import { MAX_PAGINATION_LIMIT } from "../../../../../../constants";
import { axios } from "../../../../../../App";
import qs from "qs";
import _ from "lodash";

const { Option } = Select;
const { Panel } = Collapse;

const BudgetTrackerEdit = (props) => {
  const navigate = useNavigate();
  const { id } = useParams()
  const [searchParams] = useSearchParams();

  const project = searchParams.get('project');
  const budget_id = searchParams.get('tracker');

  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [allValues, setAllValues] = useState({});
  const [selectedMilestones, setSelectedMilestones] = useState([]);
  const [subMilestonesMap, setSubMilestonesMap] = useState({});
  const [data, setData] = useState([])

  const [values, setValues] = useState({
    status: ["Pending", "Approved", "Rejected"]?.map((item) => ({
      value: item,
      label: item,
    })),
    work_unit: [],
    all_work_item: [],
  });


  const contentTypes = [
    {
      slug: "tasks",
      query: {
        filters: {
          $and: [
            {
              type: "Milestone",
              project: project
            },
          ]
        },
      },
      transformer: (item) => ({
        ...item,
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          task: data,
        })),
    },
  ];

  const fetchBudgetData = async (params = null) => {
    try {

      const queryObject = {
        populate: "*",
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `budget-tracker-phases/${budget_id}?${qs.stringify(queryObject)}`
      );

      console.log('response', response);

      if (response?.data) {
        setData(response?.data?.data);
        setSubMilestonesMap(response?.data?.data?.attributes?.sub_milestone)
        setAllValues(response?.data?.data?.attributes)
      }

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    fetchBudgetData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddSubMileStone = (milestoneId) => {
    setSubMilestonesMap((prev) => {
      const prevSubMilestones = prev[milestoneId] || [];
      return {
        ...prev,
        [milestoneId]: [
          ...prevSubMilestones,
          {
            id: v4(),
            milestone: milestoneId,
            sub_milestone: '',
            estimated_labour_cost: '',
            actual_labour_cost: '',
            estimated_material_cost: '',
            actual_material_cost: '',
            estimated_budget: '',
            actual_budget: '',
          },
        ],
      };
    });
  };


  const onEditSubMilestone = (milestoneId, subMilestoneId, key, value) => {
    setSubMilestonesMap((prev) => {
      const subMilestones = prev[milestoneId] || [];
      const updatedSubMilestones = subMilestones.map((item) => {
        if (item.id === subMilestoneId) {
          const updatedItem = { ...item, [key]: value };
          // Calculate estimated_budget
          if (key === 'estimated_labour_cost' || key === 'estimated_material_cost') {
            const labourCost = parseFloat(updatedItem.estimated_labour_cost) || 0;
            const materialCost = parseFloat(updatedItem.estimated_material_cost) || 0;
            updatedItem.estimated_budget = labourCost + materialCost;
          }
          if (key === 'actual_labour_cost' || key === 'actual_material_cost') {
            const labourCost = parseFloat(updatedItem.actual_labour_cost) || 0;
            const materialCost = parseFloat(updatedItem.actual_material_cost) || 0;
            updatedItem.actual_budget = labourCost + materialCost;
          }
          return updatedItem;
        }
        return item;
      });
      return {
        ...prev,
        [milestoneId]: updatedSubMilestones,
      };
    });
  };


  const handleValuesChange = (_, inputValues) => {
    setAllValues((prev) => ({
      ...prev,
      ...inputValues,
    }));
  };

  const { total_estimated_budget, total_actual_budget } = useMemo(() => {
    let totalEstimated = 0;
    let totalActual = 0;

    Object.values(subMilestonesMap).forEach((subMilestones) => {
      subMilestones.forEach((subMilestone) => {
        totalEstimated += parseFloat(subMilestone.estimated_budget) || 0;
        totalActual += parseFloat(subMilestone.actual_budget) || 0;
      });
    });

    return {
      total_estimated_budget: totalEstimated,
      total_actual_budget: totalActual,
    };
  }, [subMilestonesMap]);

  const handleFinish = async () => {
    setSubmitting(true);

    const values = {
      ...allValues,
      milestone: selectedMilestones,
      total_estimated_budget,
      total_actual_budget,
      sub_milestone: subMilestonesMap,
      budget_tracker: id
    }

    const response = await config?.actions?.onFinish(values, data);

    if (response) {
      if (response?.success) {
        notification["success"]({
          message: config?.title,
          description: response?.message,
        });
        config?.actions?.onBack();
      } else {
        notification["error"]({
          message: config?.title,
          description: response?.message,
        });
      }
    }

    setSubmitting(false);
  };

  useEffect(() => {
    setSelectedMilestones(data?.attributes?.milestone?.data?.map((mile) => mile?.id))
  }, [data])

  useEffect(() => {
    if (allValues) {
      form.setFieldsValue(allValues);
    }
  }, [allValues, form]);


  console.log('Edit values', values?.task)

  return (
    <>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.title}
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      <Form
        form={form}
        name={config?.id}
        layout="vertical"
        onFinish={handleFinish}
        style={styles.form}
        scrollToFirstError
        onValuesChange={handleValuesChange}
      >
        <Row gutter={24} style={{ marginBottom: '20px' }}>
          <Col span={24}>
            <Select
              placeholder="Select Milestones"
              value={selectedMilestones}
              onChange={setSelectedMilestones}
              options={values?.task}
              style={{ width: "100%" }}
              mode="multiple"
            />

          </Col>
        </Row>


        {selectedMilestones?.length > 0 && (
          <div style={{ border: '1px solid #e8e8e8', padding: '10px', marginBottom: '20px' }}>
            <p>Milestones</p>

            {values?.task
              ?.filter((item) => selectedMilestones?.includes(item?.value))
              ?.map((milestone, index) => {
                const milestoneId = milestone.value;
                const subMilestones = subMilestonesMap[milestoneId] || [];

                return (
                  <div key={milestoneId} style={{ margin: '10px 0' }}>
                    <Collapse>
                      <Panel header={milestone?.label} key={milestoneId}>
                        {subMilestones?.map((data, index) => (
                          <div key={data.id} style={{ margin: '10px' }}>
                            <Collapse>
                              <Panel header={`Sub Milestone ${index + 1}`} key={data.id}>
                                <Row gutter={24} style={{ marginTop: '10px' }}>
                                  {/* Estimated Labour Cost */}
                                  <Col span={16}>
                                    <Input
                                      type="input"
                                      placeholder="Sub Milestone Name"
                                      value={data?.sub_milestone}
                                      onChange={(e) =>
                                        onEditSubMilestone(
                                          milestoneId,
                                          data.id,
                                          'sub_milestone',
                                          e.target.value
                                        )
                                      }
                                      style={{ width: '100%' }}
                                    />
                                  </Col>
                                </Row>

                                <Row gutter={24} style={{ marginTop: '10px' }}>
                                  {/* Estimated Labour Cost */}
                                  <Col span={8}>
                                    <Input
                                      type="number"
                                      placeholder="Estimated Labour Cost"
                                      value={data?.estimated_labour_cost}
                                      onChange={(e) =>
                                        onEditSubMilestone(
                                          milestoneId,
                                          data.id,
                                          'estimated_labour_cost',
                                          e.target.value
                                        )
                                      }
                                      style={{ width: '100%' }}
                                    />
                                  </Col>

                                  {/* Estimated Material Cost */}
                                  <Col span={8}>
                                    <Input
                                      type="number"
                                      placeholder="Estimated Material Cost"
                                      value={data?.estimated_material_cost}
                                      onChange={(e) =>
                                        onEditSubMilestone(
                                          milestoneId,
                                          data.id,
                                          'estimated_material_cost',
                                          e.target.value
                                        )
                                      }
                                      style={{ width: '100%' }}
                                    />
                                  </Col>

                                  {/* Estimated Budget */}
                                  <Col span={8}>
                                    <Input
                                      type="number"
                                      disabled
                                      placeholder="Estimated Budget"
                                      value={data?.estimated_budget}
                                      style={{ width: '100%' }}
                                    />
                                  </Col>
                                </Row>

                                <Row gutter={24} style={{ marginTop: '10px' }}>
                                  {/* Estimated Labour Cost */}
                                  <Col span={8}>
                                    <Input
                                      type="number"
                                      placeholder="Actual Labour Cost"
                                      value={data?.actual_labour_cost}
                                      onChange={(e) =>
                                        onEditSubMilestone(
                                          milestoneId,
                                          data.id,
                                          'actual_labour_cost',
                                          e.target.value
                                        )
                                      }
                                      style={{ width: '100%' }}
                                    />
                                  </Col>

                                  {/* Estimated Material Cost */}
                                  <Col span={8}>
                                    <Input
                                      type="number"
                                      placeholder="Actual Material Cost"
                                      value={data?.actual_material_cost}
                                      onChange={(e) =>
                                        onEditSubMilestone(
                                          milestoneId,
                                          data.id,
                                          'actual_material_cost',
                                          e.target.value
                                        )
                                      }
                                      style={{ width: '100%' }}
                                    />
                                  </Col>

                                  {/* Estimated Budget */}
                                  <Col span={8}>
                                    <Input
                                      type="number"
                                      disabled
                                      placeholder="Actual Budget"
                                      value={data?.actual_budget}
                                      style={{ width: '100%' }}
                                    />
                                  </Col>
                                </Row>
                              </Panel>
                            </Collapse>
                          </div>
                        ))}

                        <Space style={{ width: '100%', margin: '10px' }}>
                          <Button type="primary" onClick={() => onAddSubMileStone(milestoneId)}>
                            Add
                          </Button>
                        </Space>
                      </Panel>
                    </Collapse>
                  </div>
                );
              })}
          </div>
        )}

        {/* New Input Fields */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Carpet Area per" name="carpet_area">
              <Input
                type="number"
                value={allValues?.carpet_area}
                placeholder="Enter Carpet Area"

              />
            </Form.Item>

          </Col>
          <Col span={12}>
            <Form.Item label="Construction Area" name="construction_area">
              <Input
                type="number"
                value={allValues?.construction_area}
                placeholder="Enter Construction Area"

              />
            </Form.Item>

          </Col>
        </Row>

        {/* New Input Fields */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Carpet Area Cost per sq.ft" name="carpet_area_cost">
              <Input type="number" value={allValues?.carpet_area_cost} placeholder="Enter Cost" addonAfter="sq.ft" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Construction Area Cost per sq.ft" name="construction_area_cost">
              <Input type="number" value={allValues?.construction_area_cost} placeholder="Enter Cost" addonAfter="sq.ft" />
            </Form.Item>
          </Col>
        </Row>

        {/* Total Budgets */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Total Estimated Budget">
              <Input value={total_estimated_budget} disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Total Actual Budget">
              <Input value={total_actual_budget} disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              getValueFromEvent={(value) => value}
            >
              <ReactQuill placeholder="Enter Description" />
            </Form.Item>

          </Col>
        </Row>

        <Space style={styles.buttons}>
          <Space>
            <Button onClick={config?.actions?.onBack}>Cancel</Button>

            <Button type="primary" htmlType="submit" loading={submitting}>
              Save
            </Button>
          </Space>
        </Space>
      </Form>
    </>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 16,
  },
  loaderContainer: {
    textAlign: "center",
    padding: 12,
  },
  header: {
    marginBottom: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  errors: {
    marginBottom: 24,
  },
  errorsTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 6,
  },
  alert: {
    marginBottom: 6,
  },
};

export default BudgetTrackerEdit;
