import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { Breadcrumb, Button, Col, Collapse, Form, Input, InputNumber, PageHeader, Row, Select, Space, notification } from "antd";
import { Helmet } from "react-helmet";
import FormElement from "../../../components/FormElement";
import { v4 } from "uuid";
import qs from "qs";
import _ from "lodash";
import { axios } from "../../../../../App";
import ReactQuill from "react-quill";


const { Option } = Select;
const { Panel } = Collapse;

const SubContractorBoqEdit = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const auth = useSelector((state) => state?.auth);
  const config = makeConfig({ navigate, auth });
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [boq_name, setBoq_name] = useState(null)
  const [boq_item, setBoq_item] = useState([])
  // State to hold filtered material items for each boq item
  const [filteredMaterialItems, setFilteredMaterialItems] = useState([]);

  const [values, setValues] = useState({
    status: ["Pending", "Approved", "Rejected"]?.map((item) => ({
      value: item,
      label: item,
    })),
    work_unit: [],
    all_work_item: [],
  });


  const contentTypes = [
    {
      slug: "work-groups",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_group: data,
        })),
    },
    {
      slug: "work-items",
      query: {
        populate: "*",
      },
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
        work_group: item?.work_group,
        work_unit: item?.work_unit,
      }),
      onSuccess: (data) => {
        setValues((prev) => ({
          ...prev,
          all_work_item: data,
        }));

        setFilteredMaterialItems(data)
      }
    },
    {
      slug: "work-units",
      transformer: (item) => ({
        ...item,
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          work_unit: data,
        })),
    },

    {
      slug: "tasks",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          task: data,
        })),
    },
  ];

  const fetchBoqData = async (params = null) => {
    try {

      const queryObject = {
        populate: "*",
        sort: ["name:asc"],
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `sub-contractor-boqs/${id}?${qs.stringify(queryObject)}`
      );

      console.log('Boq response', response);

      if (response?.data) {
        setBoq_name(response?.data?.data?.attributes?.name);

        setBoq_item(response?.data?.data?.attributes?.boq_item)
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    fetchBoqData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddBoq = () => {
    setBoq_item((prev) =>
      prev?.concat({
        id: v4(),
        work_group: null,
        work_item: null,
        work_unit: null,
        quantity: "",
        estimated_labour_cost: "",
        estimated_material_cost: "",
        estimated_budget: "",
        item_description: "",
      })
    );
  };

  // State to hold filtered material items for each boq item
  const onEditBoq = (id, key, value) => {
    setBoq_item((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          const updatedItem = { ...item, [key]: value };

          // Filter material items based on selected material group
          if (key === 'work_group') {
            const newfilteredMaterialItems = [...filteredMaterialItems];
            newfilteredMaterialItems[id] = values?.all_work_item
              ?.filter(
                (item) => item?.work_group?.data?.id === value
              )

            setFilteredMaterialItems(newfilteredMaterialItems);

            // Reset the work_item if the group changes
            updatedItem.work_item = null;
          }

          // Auto-select work unit based on selected work item
          if (key === 'work_item') {
            const selectedItem = values?.all_work_item?.find(
              (work) => work?.value === value
            );

            console.log('selected', selectedItem, values?.all_work_item, value)

            if (selectedItem) {
              // Set the material_unit based on selected work item
              updatedItem.work_unit = selectedItem.work_unit?.data?.id || null;
            }
          }

          // Calculate estimated_budget
          if (key === "estimated_labour_cost" || key === "estimated_material_cost") {
            const labourCost = parseFloat(updatedItem.estimated_labour_cost) || 0;
            const materialCost = parseFloat(updatedItem.estimated_material_cost) || 0;
            updatedItem.estimated_budget = labourCost + materialCost;
          }

          return updatedItem;
        }
        return item;
      })
    );
  };

  const onRemoveBoq = (id) => {
    setBoq_item((prev) => prev?.filter((item) => item?.id !== id));
    // setFilteredMaterialItems((prev) => {
    //   const newfilteredMaterialItems = { ...prev };
    //   delete newfilteredMaterialItems[id];
    //   return newfilteredMaterialItems;
    // });


    notification["success"]({
      message: `Remove Boq`,
      description: `Remove Boq successfully!`,
    });
  };


  const handleFinish = async () => {
    setSubmitting(true);
    const transform_boq = boq_item?.map(item => ({
      ...item,
      full_data: {
        work_group: values?.work_group?.find(data => data?.value === item?.work_group),
        work_item: values?.all_work_item?.find(data => data?.value === item?.work_item),
      }
    }))


    const data = {
      name: boq_name,
      boq_item: transform_boq,
    }

    const response = await config?.actions?.onFinish(data, id);

    if (response) {
      if (response?.success) {
        notification["success"]({
          message: config?.title,
          description: response?.message,
        });
        config?.actions?.onBack();
      } else {
        notification["error"]({
          message: config?.title,
          description: response?.message,
        });
      }
    }

    setSubmitting(false);
  };

  console.log('all values', filteredMaterialItems)

  return (
    <>

      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.title}
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      <Form
        form={form}
        name={config?.id}
        layout="vertical"
        onFinish={handleFinish}
        style={styles.form}
        scrollToFirstError
      >
        <Row gutter={24}>
          <Col span={12}>
            <Input
              placeholder="Enter Name"
              value={boq_name}
              onChange={(e) =>
                setBoq_name(e.target.value)
              }
            />
          </Col>
        </Row>

        {boq_item?.map((data, index) => (
          <div key={index} style={{ margin: '20px 0' }}>
            <Collapse>
              <Panel header={`Item ${index + 1}`} key="uniqueKey">
                <div style={{ border: '1px solid #e8e8e8', padding: '20px', marginBottom: '10px' }}>
                  <Row gutter={24}>
                    {/* Material Group Dropdown */}
                    <Col span={6}>
                      <Select
                        placeholder="Select Work Group"
                        value={data?.work_group}
                        onChange={(value) =>
                          onEditBoq(data?.id, "work_group", value)
                        }
                        options={values?.work_group}
                        style={{ width: "100%" }}
                      />
                    </Col>

                    {/* Item Dropdown */}
                    <Col span={6}>
                      <Select
                        placeholder="Select Work Item"
                        value={data?.work_item}
                        onChange={(value) => onEditBoq(data.id, "work_item", value)}
                        options={filteredMaterialItems}
                        style={{ width: "100%" }}
                      />
                    </Col>

                    {/* Unit Input */}
                    <Col span={6}>

                      <Select
                        disabled
                        placeholder="Select Work Unit"
                        value={data?.work_unit}
                        onChange={(value) =>
                          onEditBoq(data?.id, "work_unit", value)
                        }
                        options={values?.work_unit}
                        style={{ width: "100%" }}
                      />
                    </Col>

                    {/* Quantity Input */}
                    <Col span={6}>
                      <Input
                        type="number"
                        placeholder="Enter Quantity"
                        value={data?.quantity}
                        onChange={(e) =>
                          onEditBoq(data?.id, "quantity", e.target.value)
                        }
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginTop: '10px' }}>
                    {/* Estimated Labour Cost */}
                    <Col span={8}>

                      <Input
                        type="number"
                        placeholder="Enter Labout Cost"
                        value={data?.estimated_labour_cost}
                        onChange={(e) =>
                          onEditBoq(data?.id, "estimated_labour_cost", e.target.value)
                        }
                        style={{ width: "100%" }}
                      />
                    </Col>

                    {/* Estimated Material Cost */}
                    <Col span={8}>

                      <Input
                        type="number"
                        placeholder="Enter Material Cost"
                        value={data?.estimated_material_cost}
                        onChange={(e) =>
                          onEditBoq(data?.id, "estimated_material_cost", e.target.value)
                        }
                        style={{ width: "100%" }}
                      />
                    </Col>

                    {/* Estimated Budget */}
                    <Col span={8}>
                      <Input
                        type="number"
                        disabled
                        placeholder="Estimated Budget"
                        value={data?.estimated_budget}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginTop: '10px' }}>
                    <Col span={24}>
                      {/* <Input
                        type="input"
                        placeholder="Description"
                        value={data?.description}
                        onChange={(value) =>
                          onEditBoq(data?.id, "item_description", value)
                        }
                        rows={5}
                        style={{ width: "100%" }}
                      /> */}
                      <ReactQuill theme="snow" preserveWhitespace={true}
                        value={data?.item_description}
                        onChange={(value) =>
                          onEditBoq(data?.id, "item_description", value)
                        }
                        placeholder="Description"
                        rows={5} />
                    </Col>
                  </Row>

                </div>


                {/* Delete Button */}
                <Space style={styles.buttons}>
                  <Button type="danger" onClick={() => onRemoveBoq(data?.id)}>
                    Delete
                  </Button>
                </Space>
              </Panel>
            </Collapse>
          </div>
        ))}
        <Space style={{ width: '100%', marginBottom: '20px' }}>
          <Space style={{ width: '100%' }}>
            <Button type="primary" variant="filled" onClick={() => onAddBoq()} >
              Add BOQ Item
            </Button>
          </Space>
        </Space>

        <Space style={styles.buttons}>
          <Space>
            <Button onClick={config?.actions?.onBack}>Cancel</Button>

            <Button type="primary" htmlType="submit" loading={submitting}>
              Save
            </Button>
          </Space>
        </Space>
      </Form>
    </>
  );
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 16,
  },
  loaderContainer: {
    textAlign: "center",
    padding: 12,
  },
  header: {
    marginBottom: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  errors: {
    marginBottom: 24,
  },
  errorsTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 6,
  },
  alert: {
    marginBottom: 6,
  },
};

export default SubContractorBoqEdit;
